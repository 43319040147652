<template>
  <div class="page">
    <form class="box" id="login-form">
      <a :href="getLandingUrl" class="logo"><img src="/img/KYC-PAY-logo.png" alt="Logo"></a>
      <div class="input-group">
        <label>Two factor code</label> 
        <input class="form-control" type="text" v-model="code">
        <span class="invalid-feedback" v-if="codeError.length > 0">{{ codeError }}</span>
      </div>
      <button v-if="!load" class="btn submit" @click.prevent="login">Login</button>
      <div v-else class="load"><CircleLoad /></div>
    </form>
  </div>
</template>

<script>
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: "Login",
  components: {
    CircleLoad
  },
  data() {
    return {
      load: false,
      code: '',
      codeError: ''
    }
  },
  computed: {
    getLandingUrl () {
      return process.env.VUE_APP_LANDING_URL
    }
  },
  methods: {
    async login() {
      this.load = true
      const that = this
      this.codeError = ''
      axios.defaults.headers.common.Authorization = 'Bearer ' + this.$store.state.token
      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/two-factor-code', {
          two_factor_code: this.code,
        })
        .then(function (response) {
          if (response.data.error) {
            for (let key in response.data.errors) {
              const error = response.data.errors[key][0]
              if (key === 'two_factor_code' && error) {
                if (error == 'Code expired') {
                  that.$noty.warning('Your two factor code expired')
                  that.$store.dispatch('logout')
                  that.$router.replace({ name: "Login" })
                } else {
                  that.codeError = error  
                }
              } 
            }
            return
          }
          that.$store.dispatch('removeTwoFactorOnLogin')
          that.$router.replace({ name: "Personal" })
        })
        .catch(function (error) {
          console.log(error);
        })
      this.load = false
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &#login-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 2% auto 0;
      width: 100%;
      max-width: 30rem;
      background-color: #24292e;

      .logo {
        height: 103px;
        margin: 3rem auto;
      }

      .input-group {
        padding: .25rem 3rem;
        margin: .25rem 0 .75rem;
        width: 100%;

        @media (max-width: 576px) {
          padding: .25rem 1.5rem;
        }

        label {
          color: rgba(255, 255, 255, 0.5);
          padding-left: .5rem;
        }

        .form-control {
          width: 100%;
          height: 3.5rem;
          color: #ffffff;
          background-color: #1c2126;
          padding: .5rem 1rem .5rem 1.5rem;
          margin-top: .25rem;
          border: 1px solid #2f363d;
          border-radius: .75rem;
          box-shadow: 0 11px 23px rgba(0,0,0,.02);
          outline: none;
        }

        // input:-webkit-autofill {
        //   border: 0;
        //   -webkit-text-fill-color: #ffffff;
        //   background-color: #1c2126 !important;
        //   transition: background-color 5000s ease-in-out 0s;
        // }

        // @-webkit-keyframes autofill {
        //   to {
        //     background-color: #1c2126;
        //   }
        // }

        // input:-webkit-autofill {
        //   -webkit-animation-name: autofill;
        //   -webkit-animation-fill-mode: both;
        // }

        .invalid-feedback {
          width: 100%;
          padding-left: .5rem;
          font-size: 12px;
          color: #dc3545;
        }
      }

      .submit,
      .load {
        display: inline-flex;
        min-width: 10rem;
        min-height: 3.5rem;
        padding: .5rem 2.5rem;
        margin: 1.75rem auto 3rem;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 600;
        background-color: #62cdac;
        border: none;
        border-radius: .75rem;
      }

      .load {
        width: 10rem;
        height: 3rem;

        svg {
          width: 100%;
          height: 100%;
          animation:spin 1s linear infinite;

          @keyframes spin {
            100% {
              transform:rotate(360deg);
            }
          }
        }
      }
    }
  }
}
</style>
